import React from 'react'

import { BLOCKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { Image } from 'semantic-ui-react'
import 'twin.macro'

import SEO from 'components/shared/seo'
import useSaleOfGoods from 'content-queries/sale-of-goods'
import Layout from 'mx/layout'
import { Section } from 'mx/section'

const SaleOfGoods = () => {
  const saleOfGoods = useSaleOfGoods()
  return (
    <Layout>
      <Section>
        <div tw="prose mx-6 md:mx-auto my-20">
          {renderRichText(saleOfGoods.content, {
            renderNode: {
              [BLOCKS.EMBEDDED_ASSET]: ({
                data: {
                  target: { fields },
                },
              }) => {
                return fields ? (
                  <Image
                    src={fields.file['en-US'].url}
                    style={{ height: 'auto' }}
                  />
                ) : null
              },
            },
          })}
        </div>
      </Section>
    </Layout>
  )
}

export default SaleOfGoods

export const Head = () => (
  <SEO
    title="Sale Of Goods"
    description="Learn more about FORM Kitchens Sale Of Goods."
    path="/sale-of-goods"
  />
)
